import { BaseService } from "./base.service";
import localstorageService from "../localstorage/localstorage.service";

class AuthService extends BaseService {
  loginEndpoint = "/admins/login";
  loginOfficialEndpoint = "/admins/login-official";
  registerEndpoint = "/register";

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  login(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(this.loginEndpoint, data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  loginOfficial(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(this.loginOfficialEndpoint, data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  register(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/admins/register", data)
        .then((result) => {
          // console.log('register', result)
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  registerEtablissement(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/inscriptions/register-file", data)
        .then((result) => {
          // console.log('register', result)
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    return new Promise((resolve) => {
      localstorageService.removeJwtToken();
      localstorageService.removeJwtRefreshToken();

      // Remove userData from localStorage
      localstorageService.removeUserData();
      resolve(true);
    });
  }

  forgetPassword(data) {
    // console.log('forgetPassword', data)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/admins/forget-password", data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  codeValidation(data) {
    // console.log('codeValidation', data)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/admins/check-reset-code", data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  resetPassword(data) {
    // console.log('resetPassword', data)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put("/admins/reset-password/629651dfc0ad740b79177fda", data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setPasswordAgent(data) {
    // console.log('resetPassword', data)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/admins/set-password", data)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default new AuthService();
